import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user')),
        searchVal: "",
    },
    mutations: {
        SET: (state, obj) => {
            state[obj.key] = obj.value
        },
        SET_PERMISSION(state, data) {
            state.permission = data
        },
        searchVal: (state, val) => {
            // state.searchVal = val
            // 响应式
            Vue.set(state, 'searchVal', val)
        }
    },
    actions: {
        setToken({ commit }, token) {
            localStorage.removeItem('token');
            if (token) localStorage.setItem('token', token);
            commit('SET', { key: 'token', value: token })
        },
        /* 缓存用户 */
        setUser({ commit }, user) {
            localStorage.removeItem('user');
            if (user) localStorage.setItem('user', JSON.stringify(user));
            commit('SET', { key: 'user', value: user });
        },
        // setSearchVal({ commit }, val) {
        //     commit('SET', { key: 'searchVal', value: val });
        // },
    },
    modules: {}
})