import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/index/index.vue')
    },
    {
        // 登录
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login.vue')
    },
    {
        // 注册
        path: '/register',
        name: 'register',
        component: () => import('@/views/login/register.vue')
    },
    {
        // 忘记密码
        path: '/forget',
        name: 'forget',
        component: () => import('@/views/login/forget.vue')
    },
    {
        // 账户信息
        path: '/userinfo',
        name: 'userinfo',
        component: () => import('@/views/index/userinfo.vue')
    },
    {
        // 我的收藏
        path: '/usersc',
        name: 'usersc',
        component: () => import('@/views/index/usersc.vue')
    },
    {
        // 修改密码
        path: '/userpassword',
        name: 'userpassword',
        component: () => import('@/views/index/userpassword.vue')
    },

]

const router = new VueRouter({
    routes,
    mode: 'history'
})

// router.beforeEach((to, from, next) => {
//     if (to.path == '/login') {
//         next()
//     }
//     if (store.state.token == null) {
//         next()
//     } else {
//         next({ path: '/login' })
//     }
//     next()
// })
export default router
