<template>
  <div id="app">
    <keep-alive include="trading">
      <router-view />
      </keep-alive>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="less">
@import "./styles/common.css";
@import "./styles/overall.less";
html{
    background: #F8F8F8;
}
input{
    border: 0;
}
.s2{
    color: red !important;
    display: inline-block;
}
input:focus {
  outline: none; /* 移除点击输入框默认产生的轮廓 */
}
select:focus {
  outline: none; /* 移除点击输入框默认产生的轮廓 */
}
.bgbgbg{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: 1;
    background: url(../public/bgbgbg.png) no-repeat;
    background-size: 100% auto;
}
.bg_model{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.9);
}

</style>
