import Vue from "vue";
import {
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Menu,
    MenuItem,
    Submenu,
    Input,
    Select,
    Option,
    Tabs,
    TabPane,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Pagination,
    InputNumber,
    Table,
    TableColumn,
    Checkbox,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Steps,
    Step,
    Progress,
    Tree,
    Rate,
    CheckboxGroup,
    Message,
    MessageBox,
    DatePicker,
    Upload,
    Dialog,
    Row,
    Col,
    Cascader,
    Carousel,
    CarouselItem,
    MenuItemGroup,
    Loading
} from 'element-ui'
Vue.prototype.$message = Message;
Vue.prototype.$messageBox = MessageBox;
Vue.use(MenuItemGroup)
Vue.use(CarouselItem)
Vue.use(Carousel)
Vue.use(Cascader)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(CheckboxGroup)
Vue.use(Rate)
Vue.use(Tree)
Vue.use(Button)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Pagination)
Vue.use(InputNumber)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Progress)
Vue.use(Loading)
