import axios from "axios";
import Vue from 'vue';
import {MessageBox} from 'element-ui';
import router from "@/router";

var baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded ';

Vue.prototype.$http = axios

axios.interceptors.request.use(function (config) {
    // if(res.data.code==401){

    // }
    let access_token = localStorage.getItem('access_token')
    if (access_token) {
        config.headers.Authorization = 'Bearer ' + access_token
    }
    // 在发送请求之前做些什么
    return config;
})
axios.interceptors.response.use((response) => {
    if (response.status == 200) {
        if(response.data.code == 401){
            return router.push("/login");
        }
        return response;
    }else {
        MessageBox.alert("系统有误");
    }
});

export default baseURL
